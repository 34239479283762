import memoize from 'lodash-es/memoize'
import has from 'lodash-es/has'
import type { FieldOptionColor } from '#field/constant'
import {
  DEFAULT_FIELD_OPTION_COLOR,
  FieldOptionColorPresets,
} from '#field/constant'

export const getFieldOptionPreset = memoize(
  (optionColor?: FieldOptionColor) => {
    const key = optionColor?.toUpperCase() || DEFAULT_FIELD_OPTION_COLOR
    if (!optionColor || !has(FieldOptionColorPresets, key)) {
      return FieldOptionColorPresets[DEFAULT_FIELD_OPTION_COLOR.toUpperCase()]
    }

    return FieldOptionColorPresets[key]
  },
  (optionColor) => optionColor
)

export const formatNumber = (value: number | string): string => {
  if (typeof value === 'string') {
    value = parseFloat(value)
  }

  const result = new Intl.NumberFormat('en-US').format(value)

  if (result === 'NaN') {
    return ''
  }

  return result
}
